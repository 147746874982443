<template>
  <div v-if="isMobile" class="lesson_control_container mobile">
    <div class="lesson_control_mobile">
      <LessonDownloads v-if="getCurrentLesson.attachments && downloadStyleFile === 'menu'" />
      <div @click="sendToPrevLesson" class="btn-player-mobile" style="padding-top: 13px;">
        <svg class="svg_icon_color" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L1 9L9 17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.previous") }}</span>
      </div>
      <div @click="markLesson(getCurrentLesson)" v-if="getCurrentLesson.is_completed" class="concluded_lesson btn-player-mobile" style="padding-top: 13px;">
        <svg class="svg_icon_color" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3435 8.63665C18.5442 8.82635 18.553 9.14281 18.3633 9.34348L10.8009 17.3435C10.7064 17.4434 10.575 17.5 10.4375 17.5C10.3 17.5 10.1686 17.4434 10.0742 17.3435L6.63665 13.7071C6.44695 13.5064 6.45585 13.19 6.65652 13.0003C6.85719 12.8106 7.17365 12.8195 7.36335 13.0202L10.4375 16.2722L17.6367 8.65652C17.8264 8.45585 18.1428 8.44695 18.3435 8.63665Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"/>
        </svg>
        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.concluded") }}</span>
      </div>
      <div @click="markLesson(getCurrentLesson)" v-if="!getCurrentLesson.is_completed && liberatedToClickNext()" class="btn-player-mobile" style="padding-top: 13px;">
        <svg class="svg_icon_color_fill" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3435 8.63665C18.5442 8.82635 18.553 9.14281 18.3633 9.34348L10.8009 17.3435C10.7064 17.4434 10.575 17.5 10.4375 17.5C10.3 17.5 10.1686 17.4434 10.0742 17.3435L6.63665 13.7071C6.44695 13.5064 6.45585 13.19 6.65652 13.0003C6.85719 12.8106 7.17365 12.8195 7.36335 13.0202L10.4375 16.2722L17.6367 8.65652C17.8264 8.45585 18.1428 8.44695 18.3435 8.63665Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"/>
        </svg>
        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.conclude") }}</span>
      </div>
      <div @click="sendToNextLesson" v-if="(getHasNextLesson || getHasNextModule) && liberatedToClickNext()" class="btn-player-mobile" style="padding-top: 13px;">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 15V1M1 15L10 8L1 1V15Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.next") }}</span>
      </div>
    </div>  
  </div>
  <div v-else class="lesson_control_container nowrap">
    <div class="nowrap">
      <DropdownAutoplay
        v-if="getCurrentLesson.mediaType == 'panda'"
      />
    </div>
    <div class="nowrap pl-2">
      <div class="mr-3">
        <span style="text-align: center;" @click="markLesson(getCurrentLesson)" v-if="getCurrentLesson.is_completed" class="concluded_lesson lesson_control_mark_as_concluded">
          {{ $t("player.concluded") }}
          <svg class="ml-2 svg_icon_color_concluded" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L4.75 9L1 5.36364" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <span @click="markLesson(getCurrentLesson)" v-if="!getCurrentLesson.is_completed && getCurrentLesson.is_liberated && liberatedToClickNext()" class="lesson_control_mark_as_concluded">
          <span style="text-align: center;">
            {{ $t("player.mark_completed") }}
          </span>
          <svg class="ml-2 svg_icon_color" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L4.75 9L1 5.36364" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
      <span @click="sendToPrevLesson" v-if="getHasPrevLesson || getHasPrevModule" class="lesson_control_next_btn nowrap">
        <svg class="svg_icon_color mr-2" width="7" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 1L1 7L7 13" />
          <path d="M7 1L1 7L7 13" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span v-if="getPlayerStatus === 'loading-prev-lesson'" style="min-width: 80px;" class="skeleton"></span>
        <span v-else>
          {{ $t("player.previous") }}
        </span>
      </span>
  
      <span data-anima="left" @click="sendToNextLesson" v-if="(getHasNextLesson || getHasNextModule) && liberatedToClickNext()" class="ml-3 lesson_control_next_btn">
        <span v-if="getPlayerStatus === 'loading-next-lesson'" style="min-width: 80px;" class="skeleton"></span>
        <span class="nowrap" style="align-items: center;" v-else>
          {{ $t("player.next") }}
          <svg class="ml-2 svg_icon_color" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </span>
      <span data-anima="right" @click="() => {}" v-if="(getHasNextLesson || getHasNextModule) && !liberatedToClickNext()" class="ml-3 lesson_control_next_btn next_in">
        <span v-if="getPlayerStatus === 'loading-next-lesson'" style="min-width: 80px;" class="skeleton"></span>
        <span class="nowrap fontColorPercentage" style="align-items: center;" v-else>          
          {{ $t("player.next_in") }}
          <span style="width: 30px; padding-left: 6px;">
            {{ restantPercentage() }}%
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import LessonDownloads from '../lessonDownloads/index.vue'
import DropdownAutoplay from './DropdownAutoplay.vue'

export default {
  props: ['isMobile'],
  computed: {
    ...mapGetters([
      'getPlayerStatus',
      'getCurrentLesson', 
      'getHasNextLesson', 
      'getHasPrevLesson', 
      'getIsMobile', 
      'getHasNextModule', 
      'getHasPrevModule',
      'getPlayerConfig',
    ]),
    downloadStyleFile() { 
      return this.$store.state.metas.metas.download_style_file || "menu"
    },
  },
  methods: {
    ...mapActions([
      "sendToNextLesson", 
      "sendToPrevLesson", 
      "markLesson", 
      "fetchMetas"
    ]),
    restantPercentage() {
      const actualPercentage = this.getCurrentLesson.percent || 0;
      const target = this.getPlayerConfig.minimumPercentageToCompleteLesson - parseInt(actualPercentage);
      return target;
    },
    liberatedToClickNext() {
      const isApplicable = ['youtube', 'vimeo', 'panda'].includes(this.getCurrentLesson.mediaType);
      if (!isApplicable || !this.getPlayerConfig.limitMinimumPercentageLessonIsActive) return true;
      if (this.getCurrentLesson.is_completed) return true;

      const percent = this.getCurrentLesson.percent || 0;
      let targetPercent = this.getPlayerConfig.minimumPercentageToCompleteLesson == 100 ? 99 : this.getPlayerConfig.minimumPercentageToCompleteLesson;
      return percent >= targetPercent;
    },
  },
  components: {
    LessonDownloads,
    DropdownAutoplay
  },
  async created() {
    await this.$store.dispatch("fetchMetas", ["download_style_file"]);
  }
}
</script>

<style lang="scss">
  .next_in {
    color: var(--bordercolor-v2) !important;
    cursor: not-allowed !important;
    background: var(--background-v2) !important;
    border: 1px solid var(--bordercolor-v2) !important;
  }
  .font-btn-player-mobile {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 6px;
    color: var(--fontcolor-v2);
  }
  .lesson_control_mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .btn-player-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lesson_control_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .mobile {
    margin-top: 0px;
  }
  .lesson_control_mark_as_concluded {
    cursor: pointer;
    height: 50px;
    padding-left: 28px;
    padding-right: 28px;
    background: var(--background2-v2);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--fontcolor-v2);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lesson_control_next_btn {
    cursor: pointer;
    height: 50px;
    padding-left: 28px;
    padding-right: 28px;
    background: var(--background2-v2);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--fontcolor-v2);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lesson_control_next_btn:hover {
    background: var(--fundodowload);
    transition: background 0.2s ease-in-out;
  }
  .lesson_control_btn {
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background2-v2);
    border-radius: 50%;
    margin-right: 15px;
  }
  .lesson_control_btn:hover {
    background: var(--fundodowload);
    transition: background 0.2s ease-in-out;
  }
  .svg_icon_color {
    stroke: var(--fontcolor-v2) !important;
  }
  .svg_icon_color_fill {
    stroke: var(--fontcolor-v2);
    fill: var(--fontcolor-v2);
  }
  .svg_icon_color_only_fill {
    stroke: var(--fontcolor-v2);
  }
  .svg_icon_color_concluded {
    stroke: var(--maincolor);
  }
  .nowrap {
    display: flex;
    flex-wrap: nowrap;
  }
  .lesson_control_mark_as_concluded:hover {
    background: var(--fundodowload);
    transition: background 0.2s ease-in-out;
  }
  .skeleton {
    animation: skeleton-loading 0.6s linear infinite alternate;
    height: 12px; 
    width: 100%;
  }

  @keyframes skeleton-loading {
    0% {
      opacity: 0.5;
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
      opacity: 0.8;
    }
  }
  .btn-player-mobile svg {
    stroke: var(--fontcolor-v2);
  }
  .concluded_lesson {
    // color: var(--maincolor) ;
    // width: 25px;
    stroke: var(--maincolor) !important;
  }
.fontColorPercentage {
  color: var(--maincolor);
  opacity: 0.6;
}
</style>