<template>
  <div class="config-autoplay-div">
    <b-dropdown ref="configAutoplay" dropup class="config-autoplay">
      <template #button-content>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20" fill="none">
          <path d="M7.87502 5.61538L8.27194 4.59077C8.33888 4.41729 8.45665 4.26807 8.60983 4.16266C8.76301 4.05724 8.94446 4.00055 9.1304 4H9.88733C10.0733 4.00055 10.2547 4.05724 10.4079 4.16266C10.5611 4.26807 10.6788 4.41729 10.7458 4.59077L11.1427 5.61538L12.4904 6.39077L13.5796 6.22462C13.761 6.2 13.9456 6.22985 14.11 6.31039C14.2743 6.39093 14.411 6.51851 14.5027 6.67692L14.8719 7.32308C14.9666 7.48401 15.0101 7.66986 14.997 7.85608C14.9838 8.0423 14.9144 8.22015 14.7981 8.36615L14.1243 9.22462V10.7754L14.8166 11.6338C14.9329 11.7798 15.0022 11.9577 15.0154 12.1439C15.0286 12.3301 14.985 12.516 14.8904 12.6769L14.5212 13.3231C14.4295 13.4815 14.2928 13.6091 14.1284 13.6896C13.9641 13.7701 13.7795 13.8 13.5981 13.7754L12.5089 13.6092L11.1612 14.3846L10.7642 15.4092C10.6973 15.5827 10.5795 15.7319 10.4264 15.8373C10.2732 15.9428 10.0917 15.9995 9.90579 16H9.1304C8.94446 15.9995 8.76301 15.9428 8.60983 15.8373C8.45665 15.7319 8.33888 15.5827 8.27194 15.4092L7.87502 14.3846L6.52733 13.6092L5.4381 13.7754C5.25673 13.8 5.07213 13.7701 4.90777 13.6896C4.74341 13.6091 4.6067 13.4815 4.51502 13.3231L4.14579 12.6769C4.05117 12.516 4.00758 12.3301 4.02077 12.1439C4.03395 11.9577 4.10329 11.7798 4.21963 11.6338L4.89348 10.7754V9.22462L4.20117 8.36615C4.08483 8.22015 4.01549 8.0423 4.00231 7.85608C3.98912 7.66986 4.03271 7.48401 4.12733 7.32308L4.49656 6.67692C4.58824 6.51851 4.72495 6.39093 4.88931 6.31039C5.05367 6.22985 5.23827 6.2 5.41963 6.22462L6.50887 6.39077L7.87502 5.61538ZM7.66271 10C7.66271 10.4896 7.85722 10.9592 8.20344 11.3054C8.54966 11.6516 9.01923 11.8462 9.50887 11.8462C9.9985 11.8462 10.4681 11.6516 10.8143 11.3054C11.1605 10.9592 11.355 10.4896 11.355 10C11.355 9.51037 11.1605 9.04079 10.8143 8.69457C10.4681 8.34835 9.9985 8.15385 9.50887 8.15385C9.01923 8.15385 8.54966 8.34835 8.20344 8.69457C7.85722 9.04079 7.66271 9.51037 7.66271 10Z" stroke="var(--icon)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>
      <div class="autoplay-first">
        <p>Autoplay</p>
        <svg @click="closeModal()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--fontcolor-v2)" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
      </div>
      <div class="autoplay-control">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--fontcolor-v2)" viewBox="0 0 256 256"><path d="M208,40H48A24,24,0,0,0,24,64V176a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V64A24,24,0,0,0,208,40Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Zm-48,48a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm-3.56-110.66-48-32A8,8,0,0,0,104,88v64a8,8,0,0,0,12.44,6.66l48-32a8,8,0,0,0,0-13.32ZM120,137.05V103l25.58,17Z"></path></svg>
          <span>Ativar autoplay</span>
        </div>
        <div>
          <b-form-checkbox 
            switch 
            v-model="autoplay" 
            @change="updateAutoplay"
          />
        </div>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  data(){
    return{
      autoplay: true
    }
  },
  methods: {
    closeModal(){
      this.$refs.configAutoplay.hide();
    },
    updateAutoplay() {
      localStorage.setItem('autoplay', JSON.stringify(this.autoplay));
    },
  },
  created(){
    const savedAutoplay = localStorage.getItem('autoplay');
    if (savedAutoplay != null) {
      this.autoplay = JSON.parse(savedAutoplay); 
    } else {
      localStorage.setItem('autoplay', JSON.stringify(this.autoplay));
    }
  }
}
</script>
<style lang="scss">
.config-autoplay-div{
  .btn-secondary:focus{
    box-shadow: none !important;
    background: var(--background2-v2) !important;
  }
  .dropup .dropdown-toggle::after{
    content: none;
    border: none ;
  }
  .show > .btn-secondary.dropdown-toggle {
    background: var(--background2-v2) !important;
    border: none !important;
    position: relative;
    &::before{
      content: "";
      width: 6px;
      height: 6px;
      background: var(--maincolor);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 54px;
      border-radius: 100%;
    }
  }
  .config-autoplay {
    .dropdown-toggle {
      cursor: pointer;
      height: 50px;
      width: 50px;
      background: var(--background2-v2);
      border: none !important;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--fontcolor-v2);
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .autoplay-first {
      display: flex;
      justify-content: space-between;
      p {
        color: var(--fontcolor-v2);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
      svg{
        cursor: pointer;
      }
    }
    .autoplay-control{
      display: flex;
      justify-content: space-between;
      div:first-child{
        display: flex;
        gap: 5px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: var(--fontcolor-v2);
      }
      div:last-child{
        margin-right: -0.25rem;
      }
      .custom-control-label{
        scale: 1.2;
      }
    }
    .dropdown-menu{
      width: 300px !important;
      padding: 24px !important;
      box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.25);
      background: var(--background-v2) !important;
    }
  }
}
</style>